/** @jsx jsx */
import React, { useEffect } from "react";
import { jsx } from "theme-ui"
import Layout from "@coursemaker/gatsby-theme-coursemaker/src/components/layout";
import Courses from "@coursemaker/gatsby-theme-coursemaker/src/components/courses";
import {Link, navigate} from "gatsby";
import { isAuthenticated, coursesFromJWT, preSaleCoursesFromJWT } from "../auth/auth";


const CoursesPage = ({ pageContext }) => {
  useEffect(() => {
    if (!isAuthenticated()){
      navigate('/login')
    }
  })
  const courses = pageContext.courses;
  let schoolThemeStyle = pageContext.school?.schoolThemeStyle;
  if (!schoolThemeStyle) {
    schoolThemeStyle = {
      primaryColor: 'blue',
      secondaryColor: 'blue',
    };
  }
  let displayCourses = [];
  let displayPreEnrolledCourses = [];

  const fetchCourses = () => {
    let enrolledCourses = coursesFromJWT()

    if (enrolledCourses) {
      displayCourses = courses.filter((course) => enrolledCourses.includes(parseInt(course.id)))
    }
    return displayCourses;
  }

  const fetchPreCourses = () => {
    let preEnrolledCourses = preSaleCoursesFromJWT();

    if (preEnrolledCourses) {
      displayPreEnrolledCourses = courses.filter((course) => preEnrolledCourses.includes(parseInt(course.id)))
    }

    return displayPreEnrolledCourses
  }

  displayCourses = fetchCourses();
  displayPreEnrolledCourses = fetchPreCourses();
  let explanation;
  if(displayCourses.length === 0){
    explanation = "You have not purchased any courses or memberships."
  } else {
    explanation = ""
  }

  return (
    <Layout pageContext={pageContext} schoolThemeStyle={schoolThemeStyle}>
      <Courses courses={displayCourses}
               preEnrolledCourses={displayPreEnrolledCourses}
               paid={true} />
      {displayCourses.length === 0 &&
      <>
        <div className="text-center">
          <a href={`/`}
             sx={{
               color: "primary_blue",
             }}>
            Browse Courses
          </a>
        </div>
      </>
      }
      <p className="mt-5 text-center">{explanation}</p>

    </Layout>
  );
};

export default CoursesPage;
