import React from 'react';
import Card from '@coursemaker/gatsby-theme-coursemaker/src/components/card';
import Icon from '@coursemaker/gatsby-theme-coursemaker/src/components/icon';
import svg from '@coursemaker/gatsby-theme-coursemaker/src/images/icons/icon-courses.svg';

const Courses = ({ courses, preEnrolledCourses = [], paid = false, schoolThemeStyle = { primaryColor: 'blue' } }) => {
  if (!courses) return <p>No courses available</p>;

  return (
    <section id="courses" className="py-16 bg-gray-200 md:py-32">
      <div className="container mx-auto">
        <div className="mx-auto lg:w-3/4">
          <div className="mb-12 text-center">
            <Icon color={schoolThemeStyle?.primaryColor} source={svg} />
            <h2>Courses</h2>
          </div>
          <div className="card-list md:space-y-8 space-y-6">
            {courses.map((course) => (
              <Card
                schoolThemeStyle={schoolThemeStyle}
                course={course}
                paid={paid}
                key={`course__${course.id}`}
              />
            ))}

            {preEnrolledCourses && preEnrolledCourses.length > 0 && preEnrolledCourses.map((course) => (
              <Card
                schoolThemeStyle={schoolThemeStyle}
                course={course}
                paid={paid}
                key={`course__${course.id}`}
                isPreEnrolledCourses={preEnrolledCourses.length !== 0}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Courses;
